@font-face {
  font-family: "Tenor Sans";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("/fonts/tenorsans_n4.woff2") format("woff2");
}

@font-face {
  font-family: "Nunito Sans";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("/fonts/nunitosans_n4.woff2") format("woff2");
}

@font-face {
  font-family: "Nunito Sans";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("/fonts/nunitosans_n6.woff2") format("woff2");
}

:root {
  --swiper-theme-color: #1c1b1b !important;
}

a {
  text-decoration: none !important;
}

.error-div {
  display: flex;
  flex-direction: column;
  padding: 0 80px;
  margin: 0 auto;
  max-width: 700px;
  align-items: center;
  @media only screen and (max-width: 600px) {
    padding: 0px 30px;
    font-size: 7px;
  }
}

.error-404-title {
  font-size: 21px;
  font-family: Nunito Sans;
  font-weight: 300;
  line-height: 1.0833333333;
  letter-spacing: -1px;
  font-style: normal;
  color: #1c1b1b;
  transition: color 0.2s ease-in-out;
  margin-bottom: 0px;
}

.error-title {
  font-family: Nunito Sans;
  font-size: 14px;
  line-height: 1.7333333333;
  text-align: center;
  font-weight: 400;
  color: rgb(26, 26, 26);
  margin-bottom: 2rem;
}

.back-to-home {
  padding: 12px 24px;
  border: 1px solid transparent;
  color: #fff;
  border-color: #870c1d;
  border-radius: 0;
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.2em;
  font-family: Nunito Sans;
  font-weight: 300;
  font-style: normal;
  background-color: #870c1d;
  max-width: 400px;
  margin-bottom: 21px;
  @media only screen and (max-width: 600px) {
    padding: 10px 18px;
    font-size: 11px;
  }
}
